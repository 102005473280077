
<style scoped lang="less">
.c_study_nav {
  background: #fff;
  padding: 20px;
  .stu-module-header {
    margin-bottom: 30px;
  }
  .total {
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
    span {
      display: inline-block;
      margin: 0 5px;
    }
  }
  .c_study_nav_ul {
    min-height: 300px;
    li {
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .content {
    .imgs {
      width: 100%;
      max-width: 60%;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
<template>
  <div class="c_study_nav">
    <div class="stu-module-header">
      <div class="stu-module-title">学习导航</div>
    </div>
    <!-- <div class="total">
      共<span>{{ total }}</span
      >页
    </div> -->
    <!--    <ul class="c_study_nav_ul" v-loading="loading">-->
    <!--      <li v-for="(item, index) in imageList" :key="index">-->
    <!--        <img v-if="fileType(item.url) === 'image'" :src="item.url" alt="" />-->
    <!--        <template v-else-if="fileType(item.url) === 'pdf'">-->
    <!--          <PdfPreview :url="item.url" />-->
    <!--        </template>-->
    <!--        <template v-else-if="fileType(item.url) === 'file'">-->
    <!--          <el-link :href="item.url" target="_blank" type="primary"-->
    <!--            >查看</el-link-->
    <!--          >-->
    <!--        </template>-->
    <!--      </li>-->
    <!--    </ul>-->
    <!-- <el-table :data="tableData" :height="600">
      <el-table-column label="序号" align="center" width="70px">
        <template slot-scope="scope">
          <span>{{
            scope.$index + (page.page - 1) * (page.pageSize + 1)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, i) in column"
        :key="i"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.width"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
    </el-table> -->

    <div class="content" v-if="items">
      <template v-if="items.learnGuide != null">
        <div v-if="fileType === 0">
          <div style="height: 500px">
            <div
              style="
                width: 98.9%;
                height: 50px;
                background: #2c3e50;
                position: relative;
                top: 52px;
                left: 2px;
              "
            ></div>
            <iframe
              scrolling="auto"
              style="width: 100%; height: 100%"
              :src="items.learnGuide"
            >
            </iframe>
          </div>
        </div>
        <div v-if="fileType === 1">
          <div style="height: 500px">
            <div
              style="
                width: 100%;
                height: 30px;
                background: #f3f2f1;
                position: relative;
                top: 92px;
                left: 2px;
              "
            ></div>
            <iframe
              id="iframe"
              scrolling="auto"
              style="width: 100%; height: 100%"
              :src="fileUrl"
            >
            </iframe>
          </div>
        </div>
        <div v-if="fileType === 2">
          <img :src="items.learnGuide" alt="" class="imgs" />
        </div>
      </template>
    </div>
    <div v-else>暂无材料！</div>
  </div>
</template>

<script>
import { ShowModifyCourseById } from "@/libs/api/course";
import PdfPreview from "@/components/student/PdfPreview";
export default {
  name: "c_study_nav",
  components: { PdfPreview },
  data() {
    return {
      items: null,
      fileType: null,
      imageList: [],
      fileUrl: "",
      loading: false,
      column: [
        { label: "课程名称", prop: "courseName" },
        { label: "", prop: "" },
      ],
      tableData: [],
      tableTotal: 0,
      page: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    total() {
      return this.imageList.length;
    },
    course() {
      return this.$store.getters.getStuMyCourse;
    },
    // fileType() {
    //   return (url) => {
    //     if (!url) return;
    //     const type = url.substr(url.length - 4, url.length);
    //     if (type === ".pdf") return "pdf";
    //     if (".jpg.jpeg.png.JPG.JPEG.PNG.gif".includes(type)) return "image";
    //     if ("docx.doc.xls.xlsx".includes(type)) {
    //       return "file";
    //     }
    //   };
    // },
  },
  activated() {
    this.course && this.init();
  },
  methods: {
    init() {
      this.openLoadingView();
      this.items = null;
      ShowModifyCourseById({ id: this.course.id })
        .then((res) => {
          this.items = res.data;
          if (this.items.learnGuide) this.loadingView.close();
          if (null != this.items.learnGuide && this.items.learnGuide !== "") {
            let learnGuideArray = this.items.learnGuide.split(".");
            let suffix = learnGuideArray[learnGuideArray.length - 1];
            if (suffix === "pdf" || suffix === "PDF") {
              this.fileType = 0;
            }
            if (
              suffix === "doc" ||
              suffix === "DOC" ||
              suffix === "docx" ||
              suffix === "DOCX"
            ) {
              this.fileType = 1;
              this.fileUrl =
                "https://view.officeapps.live.com/op/view.aspx?src=" +
                this.items.learnGuide;
            }
            let imgList = [
              "png",
              "PNG",
              "jpg",
              "JPG",
              "jpeg",
              "JPEG",
              "bmp",
              "BMP",
              "gif",
              "GIF",
            ];
            let result = imgList.some(function (item) {
              return item === suffix;
            });
            if (result) {
              this.fileType = 2;
            }
            this.loadingView.close();
          } else {
            this.loadingView.close();
          }
        })
        .catch((err) => {
          this.loadingView.close();
        });
    },
    wordDetail() {
      // 微软提供的方法
      window.open(
        "https://view.officeapps.live.com/op/view.aspx?src=" + this.fileUrl
      );
    },
  },
};
</script>
